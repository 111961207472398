




























import { debounce } from "@/helpers/debounce";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { Component, Vue, Watch } from "vue-property-decorator";

const ContactSelectProps = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    cDefaultValue: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    query: {
      type: String,
    },
  },
});

@Component
export default class ContactSelect extends ContactSelectProps {
  contactOption = {
    data: [] as ContactData[],
    search: "",
    fetching: true,
    initialData: [] as ContactData[],
  };

  async created() {
    await this.getContactList(true, this.query);
  }

  @Watch("cDefaultValue")
  onChangeDefaultValue(newValue: string): void {
    if (newValue && !this.contactOption.data.find(c => c.id === newValue)) {
      this.fetchMoreContact();
    }
  }

  onMeta(option: ContactData): void {
    this.$emit("meta", { meta: option });
  }

  async getContactList(firstLoad = false, query?: string) {
    try {
      this.contactOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: query ?? "",
        sorts: "firstName:asc",
      };
      const res = await contactServices.listContactData(param);
      this.contactOption.data = res.data;
      this.$emit("on-getListContact", res.data);
      if (firstLoad) {
        this.contactOption.initialData = res.data;
      }
      this.contactOption.fetching = false;
    } catch (error) {
      this.contactOption.fetching = false;
    }
  }

  searchContact(value: string): void {
    debounce(() => {
      const searchBy: string[] = [];

      if (value)
        searchBy.push(
          `firstName~*${value}*_OR_lastName~*${value}*_OR_email~*${value}*`
        );

      if (this.query) searchBy.push(this.query);

      this.getContactList(false, searchBy.join("_AND_"));
    });
  }

  handleChange(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }

  /**
   * Fetch contact list from API until matching with @this value
   */
  async fetchMoreContact() {
    try {
      this.contactOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 200,
        page: 0,
        search: this.query ?? "",
        sorts: "firstName:asc",
      };
      while (
        !this.contactOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const contactOptions = await contactServices.listContactData(param);
        totalPage = Math.ceil(contactOptions.totalElements / 200);
        this.contactOption.data = contactOptions.data;
        param.page++;
      }
      this.$emit("on-getListContact", this.contactOption.data);
      this.contactOption.fetching = false;
    } catch (error) {
      this.contactOption.fetching = false;
    }
  }
}
